<template>
  <div class="dashboard-wrap">
    <div class="dashboard-header">
      <div class="count-wrap">共{{ state.total }}条数据</div>
      <div class="dashboard" @click="dashboardHandle">
        <Icon class-prefix="iconfont" name="list" size="10" color="#0C86FE" />
        <span class="name">列表模式</span>
      </div>
    </div>
    <Card title="发表趋势">
      <EcHistogram
        echartsId="roche-publishdate"
        :height="230"
        :chartData="state.publishData"
        :loading="state.publishLoading"
        @on-click="(data) => { clickChartHandle(data, '发表趋势') }"
      />
    </Card>
    <Card title="作者排行">
      <EcBar
        echartsId="roche-author"
        :height="500"
        :chartData="state.authorData"
        :loading="state.authorLoading"
        @on-click="(data) => { clickChartHandle(data, '作者排行') }"
      />
    </Card>
    <Card title="机构排行">
      <EcBar
        echartsId="roche-institute"
        :height="500"
        :chartData="state.instituteData"
        :loading="state.instituteLoading"
        @on-click="(data) => { clickChartHandle(data, '机构排行') }"
      />
    </Card>
    <Card title="研究信息统计">
      <div class="study-wrap">
        <TabBtn :tabList="tabList" :current="state.tabCurrent"  @changeList="changeStudyHandle" />
      </div>
      <EcPie
        v-if="state.tabCurrent === 'disease'"
        echartsId="roche-disease"
        :height="disease.chartData.length >= 11 ? 420 : 339"
        :center="disease.chartData.length >= 11 ? ['50%', '35%'] : ['50%', '43%']"
        :chartData="disease.chartData"
        :loading="disease.loading"
        @on-click="(data) => { clickChartHandle(data, '相关疾病') }"
      />
      <EcPie
        v-if="state.tabCurrent === 'clinical'"
        echartsId="roche-clinical"
        :height="339"
        :chartData="clinical.chartData"
        :loading="clinical.loading"
        @on-click="(data) => { clickChartHandle(data, '临床应用') }"
      />
      <EcPie
        v-if="state.tabCurrent === 'population'"
        echartsId="roche-population"
        :height="339"
        :chartData="population.chartData"
        :loading="population.loading"
        @on-click="(data) => { clickChartHandle(data, '研究人群') }"
      />
    </Card>
    <Card title="文献类型">
      <EcPie
        echartsId="roche-literatureType"
        :height="339"
        :chartData="state.literatureData"
        :loading="state.literatureLoading"
        @on-click="(data) => { clickChartHandle(data, '文献类型') }"
      />
    </Card>
    <Card title="语言类型">
      <EcHistogram
        echartsId="roche-language"
        :height="230"
        :chartData="state.languageData"
        :loading="state.languageLoading"
        @on-click="(data) => { clickChartHandle(data, '语言类型') }"
      />
    </Card>
    <Card title="影响因子">
      <EcPolar
        echartsId="roche-impactfactor"
        :height="300"
        :chartData="state.impactfactorData"
        :loading="state.impactfactorLoading"
        @on-click="(data) => { clickChartHandle(data, '影响因子') }"
      />
    </Card>
    <Card title="高频关键词">
      <EcBubble
        echartsId="roche-keywords"
        :height="400"
        :chartData="state.keywordsData"
        :loading="state.keywordsLoading"
      />
    </Card>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Icon } from 'vant'
import Card from '@/components/card/'
import { EcBar, EcHistogram, EcPie, EcPolar, EcBubble } from '@/components/charts/'
import TabBtn from './components/TabBtn'
import { deepCopy, deleteObjEmptyData } from '@/utils/'
import {
  getPublishDate,
  getAuthorData,
  getInstituteData,
  getDiseaseData,
  getClinicalData,
  getPopulationData,
  getArticleTypeData,
  getImpactFactorData,
  getKeywordsData,
  getLanguageData
} from '@/api/'
export default {
  props: {
    filter: {
      type: Object,
      default: () => null
    },
    retrieval: String
  },
  components: {
    Icon,
    Card,
    EcBar,
    EcHistogram,
    EcPie,
    EcPolar,
    EcBubble,
    TabBtn
  },
  setup (props, { emit }) {
    const state = reactive({
      total: 0,
      publishLoading: true,
      publishData: [],
      authorLoading: true,
      authorData: [],
      instituteLoading: true,
      instituteData: [],
      literatureLoading: true,
      literatureData: [],
      languageLoading: true,
      languageData: [],
      impactfactorLoading: true,
      impactfactorData: [],
      keywordsLoading: true,
      keywordsData: [],
      tabCurrent: 'disease'
    })
    const disease = reactive({
      loading: true,
      chartData: []
    })
    const clinical = reactive({
      loading: true,
      chartData: []
    })
    const population = reactive({
      loading: true,
      chartData: []
    })
    const tabList = [
      { name: '相关疾病', key: 'disease' },
      { name: '临床应用', key: 'clinical' },
      { name: '研究人群', key: 'population' }
    ]
    const route = useRoute()
    const router = useRouter()
    let params = {}

    // 发表趋势
    const fetchPublishDate = () => {
      state.publishLoading = true
      getPublishDate(params).then(res => {
        state.publishLoading = false
        if (res.code === 200) {
          state.total = res.data.all_count
          state.publishData = res.data.data.length > 10
            ? res.data.data.slice(-10).map(item => ({
              ...item,
              key: 'publish_year__in'
            }))
            : res.data.data.map(item => ({
              ...item,
              key: 'publish_year__in'
            }))
        }
      })
    }

    // 发表作者排行
    const fetchAuthordata = () => {
      state.authorLoading = true
      getAuthorData(params).then(res => {
        state.authorLoading = false
        if (res.code === 200) {
          state.authorData = res.data.filter(item => item.value !== 0).map(item => ({
            ...item,
            key: 'article__authors__contains'
          }))
        }
      })
    }

    // 机构排行
    const fetchInstituteData = () => {
      state.instituteLoading = true
      getInstituteData(params).then(res => {
        state.instituteLoading = false
        if (res.code === 200) {
          state.instituteData = res.data.filter(item => item.name).map(item => ({
            ...item,
            key: 'institute'
          }))
        }
      })
    }
    // 相关疾病
    const fetchDiseaseData = () => {
      disease.loading = true
      getDiseaseData(params).then(res => {
        disease.loading = false
        if (res.code === 200) {
          disease.chartData = res.data.data.map(item => ({
            ...item,
            key: 'disease__name'
          }))
        }
      })
    }
    // 临床应用
    const fetchClinicalData = () => {
      clinical.loading = true
      getClinicalData(params).then(res => {
        clinical.loading = false
        if (res.code === 200) {
          clinical.chartData = res.data.data.map(item => ({
            ...item,
            key: 'clinical_application__name'
          }))
        }
      })
    }
    // 研究人群
    const fetchPopulationData = () => {
      population.loading = true
      getPopulationData(params).then(res => {
        population.loading = false
        if (res.code === 200) {
          population.chartData = res.data.data.map(item => ({
            ...item,
            key: 'population__name'
          }))
        }
      })
    }

    // 文献类型
    const fetchArticleData = () => {
      state.literatureLoading = true
      getArticleTypeData(params).then(res => {
        state.literatureLoading = false
        if (res.code === 200) {
          state.literatureData = res.data.data.map(item => ({
            ...item,
            key: 'article_type__name'
          }))
        }
      })
    }

    // 语言类型
    const fetchLanguageData = () => {
      state.languageLoading = true
      getLanguageData(params).then(res => {
        state.languageLoading = false
        if (res.code === 200) {
          state.languageData = res.data.data.map(item => ({
            ...item,
            key: 'language__in'
          }))
        }
      })
    }

    // 影响因子
    const fetchImpactFactorData = () => {
      state.impactfactorLoading = true
      getImpactFactorData(params).then(res => {
        state.impactfactorLoading = false
        if (res.code === 200) {
          state.impactfactorData = res.data.data.filter(item => item.value !== 0).map(item => ({
            ...item,
            key: 'impact_factor__range'
          }))
        }
      })
    }

    // 高频关键词
    const fetchKeywordsData = () => {
      state.keywordsLoading = true
      getKeywordsData(params).then(res => {
        state.keywordsLoading = false
        if (res.code === 200) {
          state.keywordsData = res.data
        }
      })
    }

    const enums = new Map(([
      ['disease', fetchDiseaseData],
      ['clinical', fetchClinicalData],
      ['population', fetchPopulationData]
    ]))

    const studyMessage = () => {
      const handleType = enums.get(state.tabCurrent)
      handleType()
    }

    watch(() => props.filter, val => {
      // 父组件计算属性会根据路由变动，后续优化
      if (JSON.stringify(deleteObjEmptyData(val)) !== '{}') {
        params = {
          field__id: route.query.field_id,
          ...val
        }
        fetchPublishDate()
        fetchAuthordata()
        fetchInstituteData()
        fetchArticleData()
        fetchLanguageData()
        fetchImpactFactorData()
        fetchKeywordsData()
        studyMessage()
      }
    }, { deep: true, immediate: true })

    const changeStudyHandle = (item) => {
      state.tabCurrent = item
      studyMessage()
    }

    const dashboardHandle = () => {
      emit('on-click')
    }

    const clickChartHandle = (data, name) => {
      const params = deepCopy(props.filter) ? deepCopy(props.filter) : {}
      console.log(data)
      if (data.key) {
        params[data.key] = data.key === 'article__authors__contains'
          ? JSON.stringify(data.name)
          : data.key === 'impact_factor__range' ? data.filter : data.name
        params.field__id = route.query.field_id
        const chartName = `${name} = ${data.name}`
        router.push(`/dashboardList?filter=${JSON.stringify(params)}&retrieval=${props.retrieval} And ${chartName}`)
      }
    }

    return {
      state,
      disease,
      clinical,
      population,
      tabList,
      dashboardHandle,
      changeStudyHandle,
      clickChartHandle
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-wrap {
  padding: 0 15px 0 16px;
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #fff;
    border-radius: 4px;
    padding: 0 16px;
    .count-wrap {
      font-size: 14px;
      color: #999999;
    }
    .dashboard {
      width: 94px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #0C86FE;
      box-sizing: border-box;
      margin-left: 20px;
      .name {
        font-size: 14px;
        font-weight: 400;
        color: #0C86FE;
        line-height: 14px;
        margin-left: 7px;
      }
    }
  }
  .study-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
